import React from "react"
import { graphql } from "gatsby"
import Layout from "./Layout"
import ClientHero from "../components/clients/client-hero"
import ClientOpportunity from "../components/clients/client-opportunity"
import ClientSolutions from "../components/clients/client-solutions"
import ClientImpact from "../components/clients/client-impact"
import ClientTestimonial from "../components/clients/client-testimonial"
import ClientSimilar from "../components/clients/client-similar-work"
import ClientAdditional from "../components/clients/client-additional"
import { seoHead } from "../components/utils.js"

export const query = graphql`
  query ($id: ID!, $pageId: Int) {
    wpgraphql {
      page(id: $id) {
        id
        title
        uri
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
        featuredImage {
          node {
            altText
            mimeType
            mediaDetails {
              file
            }
          }
        }
        cfClientsSingleAll {
          singleClientHeroIntro
          singleClientHeroTitle
          singleClientImageCaption
          singleClientLeftHandSideTitle
          singleClientRightHandSideParagraph
          singleClientRightHandSideTitle
          singleClientImageOpp {
            altText
            mimeType
            mediaDetails {
              file
            }
          }
          singleClientSolLeftHandSideTitle
          singleClientSolRightHandSideParagraph
          singleClientSolRightHandSideTitle
          singleClientSolImageLeft {
            altText
            mimeType
            mediaDetails {
              file
            }
          }
          singleClientSolImageRight {
            altText
            mimeType
            mediaDetails {
              file
            }
          }
          singleClientImpactLeftHandSideTitle
          singleClientImpactRightHandSideParagraph
          singleClientImpactStats {
            singleClientImpactStatNumber
            singleClientImpactStatTextLabel
          }
          singleClientImpactFullWidthImage {
            mimeType
            mediaDetails {
              file
            }
          }
          singleHideTestimonial
          singleNamePosition
          singleTestimonial
          singleClientLogo {
            altText
            mimeType
            mediaDetails {
              file
            }
          }
          
          addClientsSmAddAdditionalInfo
          
          clientsSmAdditionalSections {
            clientsSmSelectContentType
            clientsSmTextFwContent
            clientsSmTextFwTitle
          }
            
          
        }

      }

      pages(where: { id: $pageId }) {
        nodes {
          cfServicesCollaborators {
            collaboratorTitle
            collaborators {
              collaboratorName
              collaboratorSubtext
              collaboratorLinkUrl {
                ... on WPGraphQL_Post {
                  id
                  title
                  uri
                }
              }
              collaboratorImage {
                altText
                mimeType
                mediaDetails {
                  file
                }
              }
            }
          }
        }
      }
    }
  }
`

const PageTemplateClientsSingle = ({ data }) => {
  const page = data.wpgraphql.page
  const collaborators = data.wpgraphql.pages.nodes[0].cfServicesCollaborators
  const hideTestim = page.cfClientsSingleAll.singleHideTestimonial || false
  const showAdditional =
    page.cfClientsSingleAll.addClientsSmAddAdditionalInfo || false

    console.log(showAdditional)

  return (
    <>
      <Layout>
        <div className="darkgrey_bg" style={{ color: "white" }}>
          <ClientHero pagedata={page} />

          <ClientOpportunity pagedata={page} />

          <ClientSolutions pagedata={page} />

          <ClientImpact pagedata={page} />

          {showAdditional && <ClientAdditional pagedata={page.cfClientsSingleAll} />}

          {!hideTestim && <ClientTestimonial pagedata={page.cfClientsSingleAll} /> }
        </div>

        <ClientSimilar pagedata={collaborators} />
      </Layout>
    </>
  )
}

export default PageTemplateClientsSingle

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const page = data.wpgraphql.page
  const seoTags = seoHead(page.seo, page.uri)
  return seoTags
}
