import React from "react"
import { Cloudinary } from "@cloudinary/url-gen"
import { AdvancedImage } from "@cloudinary/react"
import { fill } from "@cloudinary/url-gen/actions/resize"
import * as style from "./client-solutions.module.css"

const ClientOpportunity = ({ pagedata }) => {
  const lTitle =
    pagedata.cfClientsSingleAll.singleClientLeftHandSideTitle ||
    "The Opportunity"
  const rTitle =
    pagedata.cfClientsSingleAll.singleClientRightHandSideTitle || null
  const theIntro =
    pagedata.cfClientsSingleAll.singleClientRightHandSideParagraph ||
    "{the intro}"

  const altText =
    pagedata.cfClientsSingleAll.singleClientImageOpp.altText ||
    "Client Hero Image"
  const theCaption = pagedata.cfClientsSingleAll.singleClientImageCaption

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  let imgUri =
    pagedata.cfClientsSingleAll.singleClientImageOpp.mediaDetails.file

  const theImage = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${imgUri}`)

  theImage.quality("70")
  theImage.resize(fill().width(1920))
  theImage.format("auto")

  return (
    <>
      <div className="container mb-6">
        <div className="row">
          <div className="col-12 col-lg-4 mb-3 mb-lg-0">
            <h3 className="lower">{lTitle}</h3>
          </div>
          <div className="col-12 col-lg-8">
            {rTitle !== null && <h2 className="mb-4">{rTitle}</h2>}
            <h3
              className={`h4 ${style.double_break}`}
              dangerouslySetInnerHTML={{ __html: theIntro }}
            />
          </div>
        </div>
      </div>

      <div className="container mb-6">
        <div className="row">
          <AdvancedImage cldImg={theImage} alt={altText} className={`w-100`} />
          <span
            className="d-block"
            style={{
              marginTop: "10px",
              marginLeft: "10px",
            }}
          >
            {theCaption}
          </span>
        </div>
      </div>
    </>
  )
}

export default ClientOpportunity
