import React from "react"
import { Cloudinary } from "@cloudinary/url-gen"
import { AdvancedImage } from "@cloudinary/react"
import { fill } from "@cloudinary/url-gen/actions/resize"
import * as style from "./client-solutions.module.css"

const ClientSolutions = ({ pagedata }) => {
  const lTitle =
    pagedata.cfClientsSingleAll.singleClientSolLeftHandSideTitle ||
    "The Solution"
  const rTitle =
    pagedata.cfClientsSingleAll.singleClientSolRightHandSideTitle || null
  const theIntro =
    pagedata.cfClientsSingleAll.singleClientSolRightHandSideParagraph ||
    "{the intro}"

  const altText_1 =
    pagedata.cfClientsSingleAll.singleClientSolImageLeft.altText ||
    "Client Image"
  const altText_2 =
    pagedata.cfClientsSingleAll.singleClientSolImageRight.altText ||
    "Client Image"

  const theCaption = pagedata.cfClientsSingleAll.singleClientSolImageCaption

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  let imgUri_1 =
    pagedata.cfClientsSingleAll.singleClientSolImageLeft.mediaDetails.file

  const theImage_1 = cld.image(
    `${process.env.GATSBY_API_FOLDER_URL}${imgUri_1}`
  )

  theImage_1.quality("70")
  theImage_1.resize(fill().width(750).height(500))
  theImage_1.format("auto")

  let imgUri_2 =
    pagedata.cfClientsSingleAll.singleClientSolImageRight.mediaDetails.file

  const theImage_2 = cld.image(
    `${process.env.GATSBY_API_FOLDER_URL}${imgUri_2}`
  )

  theImage_2.quality("70")
  theImage_2.resize(fill().width(750).height(500))
  theImage_2.format("auto")

  return (
    <>
      <div className="container mb-6">
        <div className="row">
          <div className="col-12 col-lg-4 mb-3 mb-lg-0">
            <h3 className="lower">{lTitle}</h3>
          </div>
          <div className="col-12 col-lg-8">
            {rTitle !== null && <h2 className="mb-4">{rTitle}</h2>}
            <h3
              className={`h4 ${style.double_break}`}
              dangerouslySetInnerHTML={{ __html: theIntro }}
            />
          </div>
        </div>
      </div>
      <div className="container mb-6">
        <div className="row">
          <div className="col-12 col-lg-6 mb-3 mb-lg-0">
            <AdvancedImage
              cldImg={theImage_1}
              alt={altText_1}
              className={`w-100`}
            />
            <span
              className="d-block"
              style={{
                marginTop: "10px",
                marginLeft: "10px",
              }}
            >
              {theCaption}
            </span>
          </div>
          <div className="col-12 col-lg-6">
            <AdvancedImage
              cldImg={theImage_2}
              alt={altText_2}
              className={`w-100`}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ClientSolutions
