import React from "react"
import { cloudImage } from "../utils.js"
import * as solStyle from "./client-solutions.module.css"
import * as s from "./client-additional.module.css"

const ClientAdditional = ({ pagedata }) => {

  const sectionOutput = pagedata.clientsSmAdditionalSections.map(
    (theInfo, index) => {
      console.log(theInfo.clientsSmSelectContentType)

      if (theInfo.clientsSmSelectContentType === "text_center") {
        return (
          <div key={index} className="container mb-6 mt-6">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <h3 className="lower">{theInfo.clientsSmTextFwTitle}</h3>
              </div>
              <div className="col-12 col-lg-8 text-center">
                <div
                  className={`h4 ${s.para} ${solStyle.double_break}`}
                  dangerouslySetInnerHTML={{
                    __html: theInfo.clientsSmTextFwContent,
                  }}
                ></div>
              </div>
            </div>
          </div>
        )
      }

      if (theInfo.clientsSmSelectContentType === "text_cente") {
        return (
          <div key={index} className="container mb-6 mt-6">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <img
                  src="https://cdn.prod.website-files.com/64008b6147d0158b61bec142/646b87cfe3ba404a20311c12_SD-Imagegallery5%402x-p-3200.jpg"
                  className="mw-100"
                  style={{
                    padding: "1rem",
                    background: "rgba(255, 255, 255, .15)",
                  }}
                />
              </div>
            </div>
          </div>
        )
      }

      if (theInfo.clientsSmSelectContentType === "text_center") {
        return (
          
          <div key={index} className="container mb-6 mt-6">
            <div className="row gx-5">
              <div className="col-6">
                <h3 className="lower">Innovation consultancy</h3>

                <div
                  className={`h4 ${s.para} ${solStyle.double_break}`}
                  dangerouslySetInnerHTML={{
                    __html: theInfo.clientsSmTextFwContent,
                  }}
                ></div>
              </div>

              <div className="col-6">
                <h3 className="lower">Accessing banking services with Voice</h3>
                <div
                  className={`h4 ${s.para} ${solStyle.double_break}`}
                  dangerouslySetInnerHTML={{
                    __html: theInfo.clientsSmTextFwContent,
                  }}
                ></div>
              </div>
            </div>
          </div>
        )
      }

      if (theInfo.clientsSmSelectContentType === "text_center") {
        return "double image"
      }
    }
  )

  return <>{sectionOutput}</>
}

export default ClientAdditional
