import React from "react"
import { Cloudinary } from "@cloudinary/url-gen"
import { AdvancedImage } from "@cloudinary/react"
import { fill } from "@cloudinary/url-gen/actions/resize"
import { isSafariLess16 } from "../utils.js"

const ClientHero = ({ pagedata }) => {
  var title = pagedata.cfClientsSingleAll.singleClientHeroTitle.replace(
    " X ",
    ' <span class="small-x">&times;</span> '
  )
  title = title.replace(" x ", ' <span class="small-x">&times;</span> ')
  const theIntro = pagedata.cfClientsSingleAll.singleClientHeroIntro || "{the intro}"
  const altText = pagedata.featuredImage.node.altText || "Client Hero Image"

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  const imgUri = pagedata.featuredImage.node.mediaDetails.file
  const theImage = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${imgUri}`)

  const HeroImage = () => {
    const theHerotype = "fullwidth"
    theImage.quality("70")
    theImage.format("auto")
    let fwSizeWidth = 800
    let fwSizeHeight = 450

    if (theHerotype === "fullwidth") {
      fwSizeWidth = 1920
      fwSizeHeight = 650
      theImage.resize(fill().width(fwSizeWidth).height(fwSizeHeight))
      return (
        <AdvancedImage
          cldImg={theImage}
          alt={altText}
          className={`w-100 mb-6`}
        />
      )
    } else {
      theImage.resize(fill().width(fwSizeWidth).height(fwSizeHeight))
      return (
        <div className="container mb-6">
          <div className="row">
            <div className="col-12">
              <AdvancedImage
                cldImg={theImage}
                alt={altText}
                className={`w-100`}
              />
            </div>
          </div>
        </div>
      )
    }
  }

  const isSafariV16 = isSafariLess16()

  return (
    <>
      <div className="hero-small d-flex align-items-center darkgrey_bg">
        <div className="container">
          <div id="hide-row-when-nav-open" className="row align-items-center">
            <div className="col-12 col-lg-6 mt-5 color-white animated-headline">
              <h1
                className="lower header-fade-in"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </div>
            <div className="col-12 col-lg-6 mt-5 color-white animated-headline">
              <div className="animated-headline-wrap">
                <h2 className="lower new_style">
                  <span
                    className={`new_style_addit ${
                      isSafariV16 ? "safari_16" : ""
                    }`}
                  >
                    {theIntro}
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HeroImage />
    </>
  )
}

export default ClientHero
